const palette = {
  color1: {
    value: "#ffffff",
  },
  color2: {
    value: "#322653",
  },
  color3: {
    value: "#8873ef",
  },
};

export default palette;
